<template>
    <swiper
        :spaceBetween="30"
        :speed="2000"
        :loop="true"
        :effect="'flip'"
        :grabCursor="true"
        :autoplay="{
            delay: 2000,
            disableOnInteraction: false,
        }"
        :modules="modules"
        class="swiper swiper_03"
        >
        <swiper-slide>
            <img style="width: 100%; height: 100%; object-fit: contain; border-radius: 3%" src="../assets/images/Parallax/Fade/0.jpg" />
        </swiper-slide>
        <swiper-slide>
            <img style="width: 100%; height: 100%; object-fit: contain; border-radius: 3%" src="../assets/images/Parallax/Fade/1.jpg" />
        </swiper-slide>
        <swiper-slide>
            <img style="width: 100%; height: 100%; object-fit: contain; border-radius: 3%" src="../assets/images/Parallax/Fade/2.jpg" />
        </swiper-slide>
        <swiper-slide>
            <img style="width: 100%; height: 100%; object-fit: contain; border-radius: 3%" src="../assets/images/Parallax/Fade/3.jpg"/>
        </swiper-slide>
    </swiper>
</template>
<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/effect-cards'
    import 'swiper/css/effect-flip'
    import 'swiper/css/effect-fade';
    import 'swiper/css/autoplay';



    // import required modules
    import { EffectCards, EffectFlip, EffectFade, Autoplay } from 'swiper/modules';

    export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [ EffectCards, EffectFlip, EffectFade, Autoplay],
        };
    },
};
</script>
