<template>
    <div class="step">
        <el-steps style="max-width: 96vw; margin-left: 0vw; " :active="5"  align-center class="custom-color-steps">
            <el-step title="国际项目运营" icon="Medal" description="全面的STEM项目运营服务，涵盖科学、技术、工程和数学领域。"/>
            <el-step title="STEM夏令营" icon="Compass" description="多样化的STEM夏令营，为学生提供一个实践、探索和发现的平台。"/>
            <el-step title="进校课程服务" icon="Service" description="全方位的进校服务，包括教育资源整合、教师培训和校园活动组织。"/>
        </el-steps>
        <el-steps style="max-width: 64vw; margin-left: 12vw; margin-top: 3vw; " :active="5"  align-center class="custom-color-steps">
            <el-step title="姊妹学校交流" icon="Switch" description="姐妹学校交流项目，通过学生之间的文化交流和共同的STEM学习体验，拓宽视野，促进全球STEM教育的发展。"/>
            <el-step title="创新实验室建设" icon="School" description="建设创新实验室，提供丰富的实验设备资源和建设方案，为学校和机构提供一个促进STEM教育发展的创新空间。"/>
        </el-steps>
    </div>
</template>

<style>
.step{
    font-family: 'FangYuan';
}
/* 如果不使用 CSS 变量 */
.custom-color-steps .el-step.is-horizontal:not(:last-child)::before,
.custom-color-steps .el-step.is-vertical::before,
.custom-color-steps .el-step.is-vertical::after,
.custom-color-steps .el-step.is-vertical .el-step__line {
    background-color: #513890;
}

.custom-color-steps .el-step__title {
    color: #513890;
    font-size: calc(100vw * 28 / 1920);
    line-height: 2em;
    letter-spacing: 0.08em;
}

.custom-color-steps .el-step__head.is-finish .el-step__icon {
    color: #513890;
}

.custom-color-steps .el-step__description,
.custom-color-steps .el-step__icon.is-text {
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    line-height: 2em;
    letter-spacing: 0.08em;
}

/* 如果需要进一步自定义连接线颜色，可添加如下规则 */
.custom-color-steps .el-step__line-inner {
    border-color: #513890;
}
</style>