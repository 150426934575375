<template>
    <div class="Parallax"><swiper
        :style="{
        '--swiper-pagination-color': '#513890',
        }"
        :speed="1200"
        :parallax="true"
        :pagination="{
            clickable: true,
        }"
        :navigation="{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
            hideOnClick: true
        }"
        :modules="modules"
        :direction="'vertical'"
        class="swiper swiper_01">
        <div
            slot="container-start"
            class="parallax-bg"
            data-swiper-parallax="-23%">
        </div>
        <!-- :style="{
                'background-image':
                'url(https://swiperjs.com/demos/images/nature-1.jpg)',
            }" -->
        <swiper-slide>
            <div class="title" data-swiper-parallax="-200">ABOUT US</div>
            <div class="subtitle" data-swiper-parallax="-300">关于我们</div>
            <div class="text" data-swiper-parallax="-100">
                <p>
                    &emsp;&emsp;圣七教育专注于<b style="font-size: larger">STEM国际项目运营</b>、<b style="font-size: larger">内容培训</b>、<b style="font-size: larger">创新实验室建设</b>以及<b style="font-size: larger">进校服务。</b><br/><br/>
                    &emsp;&emsp;致力引进国际优质的教育资源，结合中国国情，<b style="font-size: larger">开发本土化课程</b>及<b style="font-size: larger">搭建国际竞赛平台</b>，帮助中国学生拓宽视野、成为具备21世纪核心素养的国际化人才。<br/><br/>
                    &emsp;&emsp;圣七教育目前运营赛事包含<b style="font-size: larger">“F1在学校”</b>、<b style="font-size: larger">“未来之城”</b>、<b style="font-size: larger">“FIRST机器人挑战赛”</b>等，自主研发创新课程引导学生将理论知识与实践知识相融汇，发挥想象力解决实际问题。<br/><br/>
                    &emsp;&emsp;<b style="font-size: larger">助力莘莘学子努力做中国科创事业的推广者，参与者和传承者，成为既有家国<br/>情怀又有国际视野的有志青年！</b>
                </p>
            </div>
            <swiper_03 data-swiper-parallax="-300"></swiper_03>
        </swiper-slide>
        <swiper-slide>
            <div class="title" data-swiper-parallax="-200">PRODUCTS & SERVICE</div>
            <div class="subtitle" data-swiper-parallax="-300">我们的业务</div>
            <div class="text" data-swiper-parallax="-100"></div>
            <step data-swiper-parallax="-100"></step>
        </swiper-slide>
        <swiper-slide>
            <div class="title" data-swiper-parallax="-200">WHY US?</div>
            <div class="subtitle" data-swiper-parallax="-300">为什么选择我们</div>
            <ParaCard data-swiper-parallax="-100"></ParaCard>
        </swiper-slide>
    </swiper>
    <div class="swiper-button-prev"><h1><el-icon class="custom"><Upload /></el-icon></h1></div>
    <div class="swiper-button-next"><h1><el-icon class="custom"><Download /></el-icon></h1></div></div>
</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

// import 'swiper/css/navigation';
import 'swiper/css/pagination';

import swiper_03 from '@/components/FadeVue.vue';
import step from '@/components/StepVue.vue';
import ParaCard from './ParaCard.vue';

// import required modules
import { Navigation, Pagination, Parallax } from 'swiper/modules';
// Navigation,
export default {
    components: {
        Swiper,
        SwiperSlide,
        swiper_03,
        step,
        ParaCard
    },
    setup() {
        return {
            modules: [ Navigation, Parallax, Pagination, ],
        };
    },
};
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 48vw;
    background: #fff;
}

.swiper-slide {
    color: #513890;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 3vw 6vw;
}

.swiper-button-next {
    left: 50%;
    top: 98vw;
    color: transparent
}

.swiper-button-prev {
    left: 50%;
    top: 56vw;
    color: transparent
}

.custom {
    font-size: calc(100vw * 32 / 1920);
    color: #513890
}

.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 130%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    /* background-image: url('../assets/images/Parallax/back1.png'); */
}

.swiper-slide .title {
    font-family: 'Regular';
    font-size: calc(100vw * 32 / 1920);
    margin-top: 3vw;
}

.swiper-slide .subtitle {
    font-family: 'FangYuan';
    font-size: calc(100vw * 28 / 1920);
    font-weight: normal;
    letter-spacing: 0.3em;
}

.swiper-slide .text {
    font-family: 'FangYuan';
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
    margin-top: 5vw;
    max-width: 52vw;
    text-align: left;
    padding: 1em;

}

.swiper-slide .image {
    position: absolute;
    top: 20vh;
    right: -6vh;
}

.swiper_03 {
    position: absolute;
    height: max-content;
    width: 28vw;
    right: 6vw;
    top: 13vw;
}

</style>