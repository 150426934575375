<template>
    <div class="camp">
        <h1>策划中，即将到来</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index">
            <h2>{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <!-- <div class="section" v-if="hasImages">
            <h2>实验室概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/School/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: 'coming soon...',
            body: '',
            }
        ],
        // Images array can be added here if available
        // images: [
        //     { src: "0.jpg", alt: '' },
        // ],
        // hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.camp {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>