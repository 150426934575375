<template>
    <div class="train">
        <h1>竞赛培训指导</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>图片</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/Train/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: '课程宗旨',
            body: '面向6-12年级学生的国际科创竞赛培训课程，从赛车设计与研究、视觉表达与平面设计、项目管理与演讲等方面帮助车队360°全方位提升竞赛水准。',
            },
            {
            title: '课程特色',
            body: '竞赛培训课程整体具有以下四个特点：\n\n        ①   深入赛车设计研究: 创作-分析-应用三位一体，通过实例解析分享，帮助学生在赛车设计应用层面实现最优效果。\n        ②  优化视觉表达与车队品牌提升:在品牌/产品越来越注重视觉品质和用户友好度的当下，优秀的设计排版与恰当的字体应用，可以明显优化视觉表达，改变品牌气质，提高传达效率。\n        ③  跨学科-全方位提升:发现问题、提出问题、解决问题的能力，同时扩展认知边界，创造更多可能；小组式合作竞争，增强学生的团队协作能力、沟通能力；打破常规的单学科和获取知识点，进阶养成逻辑思维。',
            },
        ],
        // Images array can be added here if available
        images: [
            { src: "1.jpg", alt: '图片' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.train {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>