import '@/assets/fonts/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/fonts/font.css'
// import 'lib-flexible'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus)
app.config.globalProperties.axios = axios

app.use(router).mount('#app')