<template>
    <div class="ParaCard">
        <el-row>
            <el-col :span="6" v-for="(o, index) in titles" :key="o" :offset="index > 0 ? 1 : 0">
                    <div class="head">
                        <span style="font-family: 'FangYuan'; font-size: calc(100vw * 28 / 1920); letter-spacing: 0.16em;">{{ o.text }}</span>
                    </div>
                    <img :src="require('../assets/images/Parallax/Card/'+ o.img )" style="width: 100%; box-shadow: 0.5vw 0.5vw 0 0 rgba(0, 0, 0, 0.4);"/>
                    <div class="foot">
                        <span style="font-family: 'FangYuan'; font-size: calc(100vw * 20 / 1920); font-weight: normal; letter-spacing: 0.16em;"> &emsp; {{ o.content }}</span>
                    </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            titles: [
                {text: "专业团队", content: "我们有一支经验丰富、充满激情的STEM专业教育团队，致力于为学生提供优质的学习体验。", img: "1.jpg"},
                {text: "国际资源", content: "通过与国际合作伙伴紧密合作，我们能为学生提供全球最热门、最前沿的STEM教育资源。", img: "2.jpg"},
                {text: "综合服务", content: "服务范围囊括国际项目运营、内容培训、实验室建设、进校课程，根据不同需求精心定制。", img: "3.jpg"},
            ]
        }
    },
}
</script>

<style scoped>
.el-row {
    margin-top: 6vw;
    margin-left: 12vw;
}

.head {
    background-color: #513890;
    color :#fff;
    text-align: left;
    margin-left: 1vw;
    width:max-content;
    padding: 0vw 1vw 0 1vw;
    border-radius: 1vw 1vw 0 0;
    line-height: 2.5vw;
}

.foot {
    background-color: rgba(0,0,255,0.2);
    color :#513890;
    text-align: left;
    margin : 0 1vw 0 1vw;
    padding: 1vw 1vw 1vw 1vw;
    border-radius: 0 0 1vw 1vw ;
    line-height: 1.5vw;
}
</style>