<template>
    <div class = "Nav">
        <el-menu
            :class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#ffffff"
            text-color="#513890"
            active-text-color="#80309c"
            :ellipsis="true"
            :popper-offset="16">
            <el-menu-item index="1">
                <img alt="NavMenu logo" src="../assets/images/navLogo.png" height="30px"/>
            </el-menu-item>
            <!-- disabled属性表示不可选中 -->
            <div class="flex-grow">
            </div>
            <el-sub-menu index="2" @click="roll('news')">
                <template #title>新闻与活动</template>
                <el-menu-item index="2-1" @click="newsDialogVisible = true">2023 F1在学校 世界总决赛</el-menu-item>
                <el-menu-item index="2-2" @click="newsDialogVisible = true">第十五届上海模型节日</el-menu-item>
                <el-menu-item index="2-3" @click="newsDialogVisible = true">联合国教科文组织女童和妇女教育奖</el-menu-item>
                <el-dialog
                    v-model="newsDialogVisible"
                    title="新闻与活动"
                    width="66vw"
                    :append-to-body="true"
                    align-center
                    style="font-family: 'FangYuan'"
                    destroy-on-close>
                    <TabVue01></TabVue01>
                </el-dialog>
            </el-sub-menu>
            <!-- 利用a标签实现网页跳转<a href="https://www.ele.me" target="_blank"></a> -->
            <el-sub-menu index="3" @click="roll('services')">
                <template #title>产品与服务</template>
                <el-sub-menu index="3-1">
                    <template #title>国际竞赛</template>
                    <el-menu-item index="3-1-1" @click="medalDialogVisible = true">F1 in Schools</el-menu-item>
                    <el-menu-item index="3-1-2" @click="medalDialogVisible = true">FIRST机器人挑战赛</el-menu-item>
                    <el-dialog
                        v-model="medalDialogVisible"
                        title="国际竞赛"
                        width="66vw"
                        :append-to-body="true"
                        align-center
                        style="font-family: 'FangYuan'"
                        destroy-on-close>
                        <TabVue02></TabVue02>
                    </el-dialog>
                </el-sub-menu>
                <el-menu-item index="3-2" @click="switchDialogVisible = true">国际交流</el-menu-item>
                    <el-dialog
                        v-model="switchDialogVisible"
                        width="66vw"
                        :append-to-body="true"
                        align-center
                        style="font-family: 'FangYuan'"
                        destroy-on-close>
                        <SwitchVue></SwitchVue>
                    </el-dialog>
                <el-menu-item index="3-3" @click="serviceDialogVisible = true">课程设计</el-menu-item>
                    <el-dialog
                        v-model="serviceDialogVisible"
                        width="66vw"
                        :append-to-body="true"
                        align-center
                        style="font-family: 'FangYuan'"
                        destroy-on-close>
                        <ServiceVue></ServiceVue>
                    </el-dialog>
                <el-menu-item index="3-4" @click="schoolDialogVisible = true">实验室搭建</el-menu-item>
                    <el-dialog
                        v-model="schoolDialogVisible"
                        width="66vw"
                        :append-to-body="true"
                        align-center
                        style="font-family: 'FangYuan'"
                        destroy-on-close>
                        <SchoolVue></SchoolVue>
                        <!-- <span>Open the dialog from the center from the screen</span> -->
                    </el-dialog>
            </el-sub-menu>
            <el-sub-menu index="4">
                <template #title>联系我们</template>
                <el-menu-item index="6-1" @click="contactDialogVisible = true">我们的联系方式</el-menu-item>
                <el-dialog
                    v-model="contactDialogVisible"
                    width="66vw"
                    :append-to-body="true"
                    align-center
                    style="font-family: 'FangYuan'"
                    destroy-on-close>
                    <ContactVue></ContactVue>
                    <!-- <span>Open the dialog from the center from the screen</span> -->
                </el-dialog>
            </el-sub-menu>
            <el-menu-item index="5" @click="roll('about')">企业简介</el-menu-item>
            <el-menu-item index="6" @click="roll('highlights')">成果展示</el-menu-item>
            
        </el-menu>
    </div>
    
</template>

<script>
import ContactVue from './ContactVue.vue';
import SchoolVue from './SchoolVue.vue';
import ServiceVue from './ServiceVue.vue';
import SwitchVue from './SwitchVue.vue';
import TabVue01 from './TabVue01.vue';
import TabVue02 from './TabVue02.vue';



export default {
    data() {
        return {
            // activeIndex: '1',
            // activeIndex2: '1'
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        roll: function (selector) {
            document.getElementById(selector).scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        },
        mounted: function () {
            this.roll()
        }
    },
    components: {
        TabVue01,
        TabVue02,
        SchoolVue,
        ServiceVue,
        SwitchVue,
        ContactVue,
    },
}
</script>

<style>
.el-menu {
    font-family: 'ShuHei';
}


.flex-grow {
    flex-grow: 0.9;
}
</style>