<template>
    <div class="carousel">
        <!-- <span class="demonstration">Switch when indicator is hovered (default)</span> -->
        <el-carousel height=48vw>
            <el-carousel-item v-for="item in titles" :key="item">
                <!-- <h3 class="small justify-center" text="2xl">{{ item }}</h3> -->
                <div class="box">
                    <!-- autoplay 自动播放  loop循环播放  muted 声音 preload 预加载 -->
                    <video autoplay loop muted
                        style="width: 100%; height: 100%; object-fit: fill">
                        <source :src="require('../assets/videos/'+ item.video)">
                    </video>
                    <div class="text-container">
                        <span style="font-family: 'Torque'; font-size: calc(100vw * 32 / 1920);"><h1>{{ item.text }}</h1></span>
                        <span>
                            <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                        </span>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<!-- src="../assets/videos/1.mp4" -->

<script>
export default {
    data() {
        return {
            titles: [
                {text: "2023 F1在学校 World Finals ", video: "1.mp4"},
                {text: "2022 F1在学校 Primary Class", video: "2.mp4"}
            ]
        }
    }
}
</script>

<style scoped>
.demonstration {
    color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.text-container {
    position: absolute;
    top: 38vw; /* 调整与图片之间的距离 */
    left: 50%; /* 水平居中 */
    transform: translateX(-50%); /* 水平居中 */
    background-color: rgba(0, 0, 0, 0.4); /*根据需求自定义背景色及不透明度 */
    color: #ffffff; /* 根据需求自定义文字颜色 */
    padding: 1em;
    line-height: 0em;
    height: 15vw;
    width: 100vw;
}

.el-icon {
    font-size: calc(100vw * 24 / 1920);
}

.button {
    padding: 1em;
    min-height: auto;
    color: #ffffff;
    font-weight: bold;
}

.button :hover{
    color: #513890
}
</style>